import theme from "@staticfuse/gatsby-theme-publisher/src/theme/theme"

const publisherTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    text: "#2D3748",
    primary: theme.colors.blue,
    muted: "#999",
    headings: "#2D3748",
    links: theme.colors.blue,
    headerBg: "#fff",
    footerBg: "#fff",
    footerText: "#333",
    navLink: "#000",
    navLinkLow: "#666"
  },
}


export default publisherTheme