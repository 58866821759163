module.exports = [{
      plugin: require('../node_modules/@staticfuse/gatsby-theme-publisher/gatsby-browser.js'),
      options: {"plugins":[],"starterPages":false,"mailChimpEndpoint":0,"dynamicComments":0,"gaTrackingId":0,"wordPressUrl":"https://wp.maker.co","blogURI":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Maker Stories","short_name":"Maker","start_url":"","background_color":"#f0f0f0","theme_color":"#333333","display":"standalone","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e63cbf5a56c070ced12e024805d547fd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/index.html","/*/index.html","/*.html"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://stories.maker.co","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
